import React, { useState, useEffect } from "react";
import "./index.css";
import {
  FaWhatsapp,
  FaInstagram,
  FaYoutube,
  FaPinterest,
  FaFacebookF,
  FaLinkedinIn,
  FaHeart,
} from "react-icons/fa";
import { FaMedium } from "react-icons/fa6";
import ConfettiExplosion from "react-confetti-explosion";
import { toast, Toaster } from "react-hot-toast";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const QuizPage = () => {
  const [responses, setResponses] = useState(0);
  const [displayedResponses, setDisplayedResponses] = useState(0);
  const [quizData, setQuizData] = useState({
    q1: "",
    q2: "",
    quizLive: false,
  });
  const [formData, setFormData] = useState({
    q1: "",
    q2: "",
    name: "",
    email: "",
    address: "",
  });
  const [showConfetti, setShowConfetti] = useState(false);

  const getRandomIncrement = () => Math.floor(Math.random() * 3) + 1;

  useEffect(() => {
    const fetchData = async () => {
      const db = firebase.firestore();
      const quizDoc = await db.collection("Quiz").doc("data").get();

      if (quizDoc.exists) {
        const data = quizDoc.data();
        setQuizData({
          q1: data.q1,
          q2: data.q2,
          quizLive: data.quizLive,
        });
        setResponses(data.responses);
      } else {
        console.log("Quiz data does not exist");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const updateResponses = async () => {
      const db = firebase.firestore();
      const incrementBy = getRandomIncrement();
      try {
        await db
          .collection("Quiz")
          .doc("data")
          .update({
            responses: firebase.firestore.FieldValue.increment(incrementBy),
          });
        setResponses((prev) => prev + incrementBy);
      } catch (error) {
        console.error("Error updating responses:", error);
      }
    };
    const interval = setInterval(async () => {
      await updateResponses();

      const db = firebase.firestore();
      const quizDoc = await db.collection("Quiz").doc("data").get();
      if (quizDoc.exists) {
        const data = quizDoc.data();
        setResponses(data.responses);
      }
    }, 25000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let startValue = 0;
    const duration = 2000;
    const increment = Math.ceil(responses / (duration / 100));

    if (responses > 0) {
      const counterInterval = setInterval(() => {
        startValue += increment;
        if (startValue >= responses) {
          setDisplayedResponses(responses);
          clearInterval(counterInterval);
        } else {
          setDisplayedResponses(startValue);
        }
      }, 100);

      return () => clearInterval(counterInterval);
    }
  }, [responses]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.q1 ||
      !formData.q2 ||
      !formData.name ||
      !formData.email ||
      !formData.address
    ) {
      toast.error("All fields are required!");
      return;
    }

    try {
      const db = firebase.firestore();

      await db.collection("quizResponses").add(formData);

      const response = await fetch("https://formbackend.netlify.app/quiz", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      console.log(data.message);

      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 10000);
      toast.success("Get a chance to win a surprise gift!");

      setFormData({
        q1: "",
        q2: "",
        name: "",
        email: "",
        address: "",
      });
      const incrementBy = getRandomIncrement();
      await db
        .collection("Quiz")
        .doc("data")
        .update({
          responses: firebase.firestore.FieldValue.increment(incrementBy),
        });
      setResponses((prev) => prev + incrementBy);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const renderResponseNumber = (number) => {
    const digits = number.toString().split("");
    return (
      <div className="response-number-container">
        {digits.map((digit, index) => (
          <div
            key={index}
            className={`response-number ${
              index === digits.length - 1 ? "scroll-up" : "scroll-down"
            }`}
          >
            <div className="response-digit">{digit}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="quiz-page">
      <Toaster toastOptions={{ duration: 5000 }} />
      <div className="confetti">{showConfetti && <ConfettiExplosion />}</div>
      {quizData.quizLive ? (
        <>
          <h1>Quiz Time!</h1>
          <div className="quiz-container">
            <form onSubmit={handleSubmit} className="quiz-form">
              <div className="question-section">
                <p>Question 1: {quizData.q1}</p>
                <label>
                  Answer 1:
                  <input
                    type="text"
                    name="q1"
                    value={formData.q1}
                    onChange={handleChange}
                    required
                  />
                </label>
                <p>
                  Question 2: {quizData.q2}{" "}
                  <a href="https://travelunbounded.com/" target="__blank">
                    travelunbounded.com
                  </a>
                </p>
                <label>
                  Answer 2:
                  <input
                    type="text"
                    name="q2"
                    value={formData.q2}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <div className="user-info-section">
                <label>
                  Name:
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  Email:
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  Address:
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <div className="submit-section">
                <button type="submit">Get a Surprise Gift</button>
              </div>
            </form>
            <div className="info-section">
              <div className="last-week-winner">
                <h2>Last Week's Winner</h2>
                <p>Get ready for an exciting reveal this Monday!</p>
              </div>
              <div className="response-count">
                <h2>Number of Responses</h2>
                <div className="response-number">
                  <FaHeart
                    size={30}
                    style={{ color: "red", paddingRight: "5px" }}
                  />{" "}
                  {renderResponseNumber(displayedResponses)}
                </div>
              </div>
              <div className="response-count">
                <h3>Check Our Website</h3>
                <hr />
                <a href="https://travelunbounded.com/" target="__blank">
                  Travel Unbounded
                </a>
              </div>
              <div className="response-count">
                <h3 className="text-center">Follow Us</h3>
                <hr />
                <div className="icons-container-footer">
                  <a
                    href="https://www.facebook.com/travelunboundedcom"
                    target="__blank"
                    aria-label="Facebook"
                  >
                    <div
                      className="nav-icon-footer text"
                      style={{ color: "white", backgroundColor: "#1877F2" }}
                    >
                      <FaFacebookF size={24} />
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/travel_ub/"
                    target="__blank"
                    aria-label="Instagram"
                  >
                    <div
                      className="nav-icon-footer text"
                      style={{
                        color: "white",
                        transition: "background-color 0.3s ease",
                        background:
                          "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)",
                      }}
                    >
                      <FaInstagram size={24} />
                    </div>
                  </a>
                  <a
                    href="https://www.youtube.com/@travelunbounded668/"
                    target="__blank"
                    aria-label="YouTube"
                  >
                    <div
                      className="nav-icon-footer text"
                      style={{ color: "white", backgroundColor: "#FF0000" }}
                    >
                      <FaYoutube size={24} />
                    </div>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/travel-unbounded/"
                    target="__blank"
                    aria-label="LinkedIn"
                  >
                    <div
                      className="nav-icon-footer text"
                      style={{ color: "white", backgroundColor: "#0077B5" }}
                    >
                      <FaLinkedinIn size={24} />
                    </div>
                  </a>
                  <a
                    href="https://wa.me/8792273808/"
                    target="__blank"
                    aria-label="WhatsApp"
                  >
                    <div
                      className="nav-icon-footer text"
                      style={{ color: "white", backgroundColor: "#25D366" }}
                    >
                      <FaWhatsapp size={24} />
                    </div>
                  </a>
                  <a
                    href="https://in.pinterest.com/TUB2014/"
                    target="__blank"
                    aria-label="Pinterest"
                  >
                    <div
                      className="nav-icon-footer text"
                      style={{ color: "white", backgroundColor: "#E60023" }}
                    >
                      <FaPinterest size={24} />
                    </div>
                  </a>
                  <a
                    href="https://medium.com/@Travel_ub"
                    target="__blank"
                    aria-label="Medium"
                  >
                    <div
                      className="nav-icon-footer text"
                      style={{ color: "white", backgroundColor: "#000000" }}
                    >
                      <FaMedium size={24} />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="quiz-live-message">
          <h2>🎉 The Quiz Goes Live on Friday! 🎉</h2>
          <p>
            Get ready for a chance to win amazing surprise gifts! Stay tuned!
          </p>
        </div>
      )}
    </div>
  );
};

export default QuizPage;
