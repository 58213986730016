import React, { useState } from "react";
import "./index.css";

const FAQ = ({ faqData }) => {
  const [openQuestion, setOpenQuestion] = useState(0);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  return (
    <>
      <div className="faq-container mb-5">
        {faqData.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleQuestion(index)}>
              <p>{faq.question}</p>
              <span className="icon">{openQuestion === index ? "-" : "+"}</span>
            </div>
            {/* {openQuestion === index && (
            <div className="faq-answer">
              <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
            </div>
          )} */}
            {openQuestion === index && (
              <div className="faq-answer">
                {faq.answer.map((answer, i) => (
                  <p key={i}>{answer}</p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default FAQ;
