import React from "react";
import { Link } from "react-router-dom";
import { Slide, Fade } from "react-awesome-reveal";
import "./index.css";

const DestinationCard = ({ destination, index }) => {
  return (
    <div className="row mb-0 align-items-center mb-5 mt-5">
      <div
        className={`col-md-7 ${
          index % 2 === 0 ? "order-md-1" : "order-md-2"
        } order-1 col-12 position-relative`}
      >
        <Slide
          direction={index % 2 === 0 ? "left" : "right"}
          duration={1500}
          triggerOnce
        >
          <img
            src={destination.images}
            alt="African-safari-Wildlife-tours-luxery-safari"
            className="wildlife-img"
            loading="lazy"
            width={300}
            height={200}
          />
        </Slide>
      </div>
      <div
        className={`col-md-5 ${
          index % 2 === 0 ? "order-md-2" : "order-md-1"
        } order-2 col-12`}
      >
        <div>
          <Fade>
            <h1 className="home-destination-name-head">{destination.name}</h1>
            <p className="home-destination-description">
              {destination.description}
            </p>
          </Fade>
          <div className="home-btn-container">
            <Link to={destination.link} id="readMoreBtn">
              {destination.buttonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationCard;
