import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Navbar from "../Navbar";
import { MdLocalAirport, MdDirectionsRailway } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { GiDuration } from "react-icons/gi";
import "./index.css";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaWhatsapp, FaInstagram } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import {
  FaYoutube,
  FaPinterest,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import { AiOutlineMail, AiOutlineCopyrightCircle } from "react-icons/ai";
import { FaMedium } from "react-icons/fa6";
import DummyForm from "../DummyForm";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const PackageDetails = () => {
  const { destinationName } = useParams();
  const formattedDestination = destinationName.replace(/-/g, " ");
  const [packageData, setPackageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [showEnquireButton, setShowEnquireButton] = useState(false);
  const sliderRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (sliderRef.current) {
        const sliderHeight = sliderRef.current.offsetHeight;
        const scrollPosition = window.scrollY;
        setShowEnquireButton(scrollPosition > sliderHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const fetchPackage = async () => {
      const db = firebase.firestore();
      try {
        const docRef = db
          .collection("WildlifePackageTemplate")
          .doc(formattedDestination);
        const doc = await docRef.get();
        if (doc.exists) {
          setPackageData(doc.data());
        } else {
          console.warn("No package found");
        }
      } catch (error) {
        console.error("Error fetching package:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPackage();
  }, [formattedDestination]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p className="loading"></p>
      </div>
    );
  }

  if (!packageData) {
    return <p>No package details available.</p>;
  }

  const {
    title = "",
    description = "",
    destinationHead = "",
    noOfDays = "",
    travelDates = "",
    isForeign = false,
    isOffer = false,
    corbett = false,
    offerText = "",
    specialoffer = "",
    kenya= false,
    aboutDestination = [],
    cost = "",
    newCost = "",
    originalPrice = "",
    bestTimeToTravel = "",
    nearestAirport = "",
    nearestRailwayStation = "",
    duration = "",
    date1 = "",
    date2 = "",
    date3 = "",
    contact = "",
    email = "",
    migration=false,
    migrationCost="",
    shortItinerary = [],
    itineraryTitles = [],
    inclusions = [],
    exclusions = [],
    paymentTerms = [],
    cancellationTerms = [],
    bannerImageUrl = "",
  } = packageData;

  const renderTravelDetails = () => {
    if (isForeign) {
      return (
        <>
          <div className="icon-card-foreign">
            <FaRegCalendarAlt size={40} className="icon-class" />
            <p>
              <strong>Best Time to Travel</strong>
            </p>
            <p>{bestTimeToTravel}</p>
          </div>
          <div className="icon-card-foreign">
            <MdLocalAirport size={40} className="icon-class" />
            <p>
              <strong>International Airport</strong>
            </p>
            <p>{nearestAirport}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="icon-card">
            <FaRegCalendarAlt size={40} className="icon-class" />
            <p>
              <strong>Best Time to Travel</strong>
            </p>
            <p>{bestTimeToTravel}</p>
          </div>
          <div className="icon-card">
            <MdLocalAirport size={40} className="icon-class" />
            <p>
              <strong>Nearest Airport</strong>
            </p>
            <p>{nearestAirport}</p>
          </div>
          <div className="icon-card">
            <MdDirectionsRailway size={40} className="icon-class" />
            <p>
              <strong>Nearest Railway Station</strong>
            </p>
            <p>{nearestRailwayStation}</p>
          </div>
          <div className="icon-card">
            <GiDuration size={40} className="icon-class" />
            <p>
              <strong>Duration</strong>
            </p>
            <p>{duration}</p>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <Navbar />
        {showEnquireButton && (
          <div className="enquire-buttons-package">
            <div
              className="enquire-button-package enquire-left"
              onClick={openForm}
            >
              <span className="button-text">Enquire Now</span>
            </div>
            {isFormOpen && (
              <div className="">
                <div className="">
                  <DummyForm onClose={closeForm} />
                </div>
              </div>
            )}
          </div>
        )}

        <div className="package-banner-container" ref={sliderRef}>
          {bannerImageUrl && (
            <img
              src={bannerImageUrl}
              alt={destinationName}
              className="package-img"
            />
          )}
        </div>
        <div className="package-container">
          <div className="container mt-4">
            <p
              className="mt-3 package-template-head"
              style={{ color: "#8B4513", fontWeight: "bold" }}
            >
              {destinationHead}
            </p>
            <p
              className="mt-3 package-template-text"
              style={{ color: "#8B4513" }}
            >
              {noOfDays}
            </p>
            <div className="mt-3 package-template-text">
              <span className="overview-head-package">Overview</span> <br />
              {migration && (
                <p><b>Experience the Great Migration with Travel Unbounded</b></p>
              )}
              {aboutDestination.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
              {migration && (
                <div>
                <p><b>Why Travel Unbounded?</b></p>
                <ul>
                  <li><b>Expert Guides:</b> Knowledgeable guides who bring the Mara to life.</li>
                  <li><b>Tailored for All:</b> Perfect for families, wildlife lovers, and photographers.</li>
                  <li><b>Wildlife Drama:</b> Witness the thrill of river crossings and predator hunts.</li>
                  <li><b>Comfort:</b> Enjoy luxury stays in hand-picked lodges or camps.</li>
                  <li><b>Flexible Payments:</b> Pay in easy installments to make your dream trip possible.</li>
                </ul>
                <p>Join us for an unforgettable journey through Maasai Mara and witness the Great Migration like never before. Book today with Travel Unbounded!</p>
                </div>
              )} 
            </div>
            {kenya && (
        <div className="kenya-text">
          <h1 className="mt-3">Travel Unbounded</h1>
          <p>
          We are on of the leading, Experiential travel companies headquartered at Bangalore, having our own ofﬁce in Nairobi, Kenya. 
          </p>
          <p>Since 2014, we have been catering to hundreds of guests to exotic destinations across the World. </p>
          <p>Having our own operations in East Africa, has made us one of the leading travel companies to this wonderful destination. </p>
          <p>Our expert team has curated the ‘First of its kind’ Kids tour to Kenya. This is not just a Summer holiday but a journey to learn a lot from Mother Nature</p>
          <h1 className="mt-3">5 reasons for the Kids to go on a Kenya safari
          </h1>
          <ul>
            <li>Unforgettable safari experience </li>
            <li>Highly educational - the kids will learn a lot about conservation, animal behaviour, ecosystems, culture, history, highly interactive with fun ﬁlled activities. They would learn a lot about wildlife photography, participate in healthy and constructive debates </li>
            <li>Safe and Guided by the most experienced team</li>
            <li>Kid friendly accommodations - very safe & hygienic</li>
            <li>Develops curiosity and wonder , inspires love for Nature & wildlife</li>
          </ul>
        </div>
      )}
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12 col-xl-12 col-lg-12">
                  {corbett ? (
                    <p className="offer-corbett">
                      Special Offer: Valid for bookings made on or before 31st
                      January 2025
                    </p>
                  ) : (
                    <span></span>
                  )}
                  <p className="mt-3 package-template-price">
                    {corbett ? (
                      <span>
                        <b>
                          Price:{" "}
                          <span className="blinking-price">{newCost}</span>
                        </b>
                        <span className="linethrough-price">
                          {originalPrice}
                        </span>
                        <span style={{ marginLeft: "5px" }}>PP</span>
                      </span>
                    ) : (
                      <b>{cost}</b>
                    )}
                  </p>
                  <p className="mt-3 package-template-price-corbett">
                    {corbett ? (
                      <span>
                        <b>
                          Travel Dates:{" "}
                          <span className="linethrough-corbett">{date1}</span>-
                          SoldOut |
                          <span className="linethrough-corbett">{date2}</span> -
                          SoldOut | <br /> <span className="">{date3}</span>{" "}
                          <FaCheckCircle className="icon-package green-icon" />
                        </b>
                      </span>
                    ) : (
                      <b>{travelDates}</b>
                    )}
                  </p>
                  {migration && (
                    <p className="mt-3 package-template-price"><b>{migrationCost}</b></p>
                  )}
                  {kenya ? (
                    <p className="offer-corbett">
                      {specialoffer}
                    </p>
                  ) : (
                    <span></span>
                  )}
                </div>
                {isOffer && !isFormOpen && (
                  <div className="col-12 col-md-4 col-xl-4 col-lg-4 d-flex justify-content-center align-items-center">
                    <div className="offer-button" onClick={openForm}>
                      <span className="stars">★</span>
                      <span className="offer-text">
                        {offerText}
                        <br />
                        Book Now
                      </span>
                      <span className="stars">★</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="icon-card-container mb-5">
              {renderTravelDetails()}
            </div>
            {kenya ? (
          <p
            className="mt-3 mb-3 text-center package-template-head"
            style={{ color: "#8B4513", fontWeight: "bold" }}
          >
            Itinerary (12 - 17 years)
          </p>
        ) : (
          <p
            className="mt-3 mb-3 text-center package-template-head"
            style={{ color: "#8B4513", fontWeight: "bold" }}
          >
            Short Itinerary
          </p>
        )}
            <div className="itinerary-container mb-5">
              {shortItinerary.map((item, index) => (
                <div
                  key={index}
                  className={`accordion-item ${
                    activeIndex === index ? "active" : ""
                  }`}
                >
                  <div
                    className="accordion-header"
                    onClick={() => toggleAccordion(index)}
                  >
                    <h3>{itineraryTitles[index] || `Day ${index + 1}`}</h3>
                    <span>
                      {activeIndex === index ? (
                        <FaMinus size={20} />
                      ) : (
                        <FaPlus size={20} />
                      )}
                    </span>
                  </div>
                  {activeIndex === index && (
                    <div className="accordion-body justify">
                      {item.paragraphs.map((paragraph, pIndex) => (
                        <p key={pIndex}>{paragraph}</p>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="container">
              <div className="row inclusion-exclusion-container">
                <div className="col-12 col-md-6">
                  <p className="mt-3 package-template-head inclusion-heading">
                    Inclusions
                  </p>
                  <ul className="inclusion-list">
                    {inclusions.map((item, index) => (
                      <li key={index}>
                        <FaCheckCircle className="icon-package green-icon" />
                        {item || ""}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-12 col-md-6">
                  <p className="mt-3 package-template-head exclusion-heading">
                    Exclusions
                  </p>
                  <ul className="exclusion-list">
                    {exclusions.map((item, index) => (
                      <li key={index}>
                        <FaTimesCircle className="icon-package red-icon" />
                        {item || ""}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                {paymentTerms.length > 0 && (
                  <div className="col-12 col-md-12 col-xl-12 col-lg-12">
                    <p
                      className="mt-3 package-template-head"
                      style={{ color: "#8B4513", fontWeight: "bold" }}
                    >
                      Payment Terms
                    </p>
                    <ul>
                      {paymentTerms.map((item, index) => (
                        <li key={index}>{item || ""}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {cancellationTerms.length > 0 && (
                  <div className="col-12 col-md-12 col-xl-12 col-lg-12">
                    <p
                      className="mt-3 package-template-head"
                      style={{ color: "#8B4513", fontWeight: "bold" }}
                    >
                      Cancellation Terms
                    </p>
                    <ul className="mb-3">
                      {cancellationTerms.map((item, index) => (
                        <li key={index}>{item || ""}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="enquire-container-book">
          <Link to="/form">
            <button
              className="book-btn-kenya nav-btn-tu-animate"
              style={{ borderRadius: "16px" }}
            >
              Book Now
            </button>
          </Link>
        </div>
        <div className="cancellation-btn-container mb-5 mt-3">
          <Link to="/cancellation-refund-policy" className="cancellation-btn">
            Cancellation & Refund Policy
          </Link>
        </div>
        {/*<Link to='/form' className='link'> 
          <div className='enquire-container-book mt-5 mb-5'>
            <button className='enquire-button-book'>Grab This Exclusive Offer!</button>
          </div>
          </Link> */}
        <div className="package-image-container">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/packageTemplate%2Fimg-2-1.jpg?alt=media&token=0b3c24b4-5369-4297-a276-489954fca122"
            alt="Contact"
            className="background-image-package shake-image"
          />
          <div className="package-contact-wrapper shake-text">
            <div className="package-contact-details">
              <h1 className="text-center">Contact Us</h1>
              <div className="contact-divider"></div>
              <p className="mt-3">
                <FaPhoneVolume /> {contact}
              </p>
              <p>
                <AiOutlineMail /> {email}
              </p>
            </div>
            <div className="package-contact-details">
              <h1 className="text-center">Follow Us</h1>
              <div className="contact-divider"></div>
              <div className="icons-container-footer mt-3">
                <a
                  href="https://www.facebook.com/travelunboundedcom"
                  target="__blank"
                  aria-label="Facebook"
                >
                  <div
                    className="nav-icon-footer text"
                    style={{
                      color: "white",
                      backgroundColor: "#1877F2",
                      marginLeft: "5px",
                    }}
                  >
                    <FaFacebookF size={24} />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/travel_ub/"
                  target="__blank"
                  aria-label="Instagram"
                >
                  <div
                    className="nav-icon-footer text"
                    style={{
                      color: "white",
                      marginLeft: "5px",
                      transition: "background-color 0.3s ease",
                      background:
                        "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)",
                    }}
                  >
                    <FaInstagram size={24} />
                  </div>
                </a>
                <a
                  href="https://www.linkedin.com/company/travel-unbounded/"
                  target="__blank"
                  aria-label="LinkedIn"
                >
                  <div
                    className="nav-icon-footer text"
                    style={{
                      color: "white",
                      marginLeft: "5px",
                      backgroundColor: "#0077B5",
                    }}
                  >
                    <FaLinkedinIn size={24} />
                  </div>
                </a>
                <a
                  href="https://www.youtube.com/@travelunbounded668/"
                  target="__blank"
                  aria-label="YouTube"
                >
                  <div
                    className="nav-icon-footer text"
                    style={{
                      color: "white",
                      marginLeft: "5px",
                      backgroundColor: "#FF0000",
                    }}
                  >
                    <FaYoutube size={24} />
                  </div>
                </a>
                <a
                  href="https://wa.me/917483129148?text=Hi"
                  target="__blank"
                  aria-label="WhatsApp"
                >
                  <div
                    className="nav-icon-footer text"
                    style={{
                      color: "white",
                      backgroundColor: "#25D366",
                      marginLeft: "5px",
                    }}
                  >
                    <FaWhatsapp size={24} />
                  </div>
                </a>
                <a
                  href="https://in.pinterest.com/TUB2014/"
                  target="__blank"
                  aria-label="Pinterest"
                >
                  <div
                    className="nav-icon-footer text"
                    style={{
                      color: "white",
                      backgroundColor: "#E60023",
                      marginLeft: "5px",
                    }}
                  >
                    <FaPinterest size={24} />
                  </div>
                </a>
                <a
                  href="https://medium.com/@Travel_ub"
                  target="__blank"
                  aria-label="Medium"
                >
                  <div
                    className="nav-icon-footer text"
                    style={{
                      color: "white",
                      backgroundColor: "#000000",
                      marginLeft: "5px",
                    }}
                  >
                    <FaMedium size={24} />
                  </div>
                </a>
              </div>
              <p className="margin-tag text-center mt-3">
                <AiOutlineCopyrightCircle /> Travel Unbounded
              </p>
            </div>
          </div>
        </div>
        {/* <footer>
        {isFixed ? (
          <div className="fixed-buttons">
            <Link to="/form" className="half-width-link">
              <button className="package-button">Book Now</button>
            </Link>
            <Link to={`/package/${destinationName}/pay-now`} className="half-width-link">
              <button className="package-pay-button">Pay Now</button>
            </Link>
          </div>
        ) : (
          <Link to="/form">
            <button className="package-button full-width">Book Now</button>
          </Link>
        )}
      </footer> */}
      </HelmetProvider>
    </>
  );
};

export default PackageDetails;
