import React from 'react';
import Faq from '../Faq';
import './index.css';

const faqData = [
    {
      question: "Why should I book with Travel Unbounded in Bangalore?",
      answer: ["We offer exclusive packages, expert travel advice, and unbeatable customer support to make your trip unforgettable."]
    },
    {
      question: "Does Travel Unbounded provide international tour packages?",
      answer: ["Yes, we specialize in both domestic and international travel, including destinations like Kenya, Vietnam, and Dubai."]
    },
    {
      question: "Can I customize my travel itinerary?",
      answer: ["Absolutely! We design itineraries tailored to your preferences, ensuring a unique travel experience."]
    }
  ];

const Company = () => {
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-12 col-md-12 col-lg-12 col-xl-12 company mb-3'>
                    <h1>Which Company is Best for Travel Agency in Bangalore?</h1>
                    <p>If you’re looking for the best travel agency in Bangalore, your search ends with Travel Unbounded. Known for its exceptional customer service and carefully curated tour packages, Travel Unbounded stands out as a top choice for travelers seeking memorable experiences. Whether you’re planning a luxury vacation, an adventure-packed journey, or a relaxing getaway, Travel Unbounded offers tailored solutions to meet your needs.</p>
                    <h2>Why Choose Travel Unbounded?</h2>
                    <ul>
                        <li><b>1. Wide Range of Destinations:</b> From the serene backwaters of <a href='/kerala'>Kerala</a> to thrilling safaris in <a href='/kenya'>Kenya</a> and cultural tours in <a href='/vietnam'>Vietnam</a>, we bring you the world’s most iconic locations.</li>
                        <li><b>2. Customized Packages:</b> We understand that every traveler is unique, which is why we offer personalized itineraries.</li>
                        <li><b>3. Affordable Pricing:</b> With competitive pricing, we ensure that your dream vacation fits your budget.</li>
                        <li><b>4. Expert Guidance:</b> Our team of travel experts provides <a href='https://wa.me/917483129148?text=Hi'>24/7 assistance</a>, ensuring a hassle-free journey.</li>
                        <li><b>5. Client Testimonials:</b> Hundreds of satisfied customers vouch for our professionalism and attention to detail.</li>
                    </ul>
                    <h2>Frequently Asked Questions(FAQs)</h2>
                    <Faq faqData={faqData}/>
                </div>
            </div>
        </div>
    );
}

export default Company;
