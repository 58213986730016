import React, {useState,useEffect} from 'react';
import './index.css';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { FaBookReader } from "react-icons/fa";
import { MdDeviceUnknown } from "react-icons/md";
import { TbPackages } from "react-icons/tb";
import { FaMedium } from "react-icons/fa6";
import { FaBlogger } from "react-icons/fa";
import { FcLikePlaceholder } from "react-icons/fc";
import { MdQuiz } from "react-icons/md";

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}


const DashboardHome = () => {
    const [bookingsCount, setBookingsCount] = useState(0);
    const [enquiriesCount, setEnquiriesCount] = useState(0);
    const [packages, setPackages] = useState(0);
    const [wildlife, setWildlife] = useState(0);
    const [destinations, setDestinations] = useState(0);
    const [quiz, setQuiz] = useState(0);
    const [blogs, setBlogs] = useState(0);
    const [customBlogs, setCustomBlogs] = useState(0);

    useEffect(() => {
        const fetchBookingsCount = async () => {
          const bookingsSnapshot = await firebase.firestore().collection('formData').get()
          setBookingsCount(bookingsSnapshot.size);
        };
        fetchBookingsCount();
      }, []);

      useEffect(() => {
        const fetchCancellationCount = async () => {
          const cancellationSnapshot = await firebase.firestore().collection('EnquireFormTU').get()
          setEnquiriesCount(cancellationSnapshot.size);
        };
        fetchCancellationCount();
      }, []);

      useEffect(() => {
        const fetchUsersCount = async () => {
          const usersSnapshot = await firebase.firestore().collection('PackageTemplate').get()
          setPackages(usersSnapshot.size);
        };
        fetchUsersCount();
      }, []);

      useEffect(() => {
        const fetchUsersCount = async () => {
          const usersSnapshot = await firebase.firestore().collection('WildlifePackageTemplate').get()
          setWildlife(usersSnapshot.size);
        };
        fetchUsersCount();
      }, []);


      useEffect(() => {
        const fetchVendorUsersCount = async () => {
          const vendorusersSnapshot = await firebase.firestore().collection('blogs').get()
          setBlogs(vendorusersSnapshot.size);
        };
        fetchVendorUsersCount();
      }, []);

      useEffect(() => {
        const fetchVendorUsersCount = async () => {
          const vendorusersSnapshot = await firebase.firestore().collection('customBlogs').get()
          setCustomBlogs(vendorusersSnapshot.size);
        };
        fetchVendorUsersCount();
      }, []);

      useEffect(() => {
        const fetchVendorUsersCount = async () => {
          const vendorusersSnapshot = await firebase.firestore().collection('Destinations').get()
          setDestinations(vendorusersSnapshot.size);
        };
        fetchVendorUsersCount();
      }, []);

      useEffect(() => {
        const fetchVendorUsersCount = async () => {
          const vendorusersSnapshot = await firebase.firestore().collection('quizResponses').get()
          setQuiz(vendorusersSnapshot.size);
        };
        fetchVendorUsersCount();
      }, []);

  return (
    <div>
      <h1 className='mt-1 welcome-text'>Welcome to Dashboard!</h1>
      <div className="card-container-dashboard mt-3">
        <div className="card-dashboard" style={{color:'black',background:'lightblue'}}><FaBookReader size={50}/> Bookings: {bookingsCount}</div>
        <div className="card-dashboard" style={{color:'black',background:'lightgray'}}><MdDeviceUnknown size={50}/> Enquiries: {enquiriesCount}</div>
        <div className="card-dashboard" style={{color:'black',background:'lightgreen'}}><TbPackages size={50}/> Leisure Packages: {packages}</div>
        <div className="card-dashboard" style={{color:'black',background:'rgb(221, 233, 115)'}}><TbPackages size={50}/> Wildlife Packages: {wildlife}</div>
        <div className="card-dashboard" style={{color:'black',background:'rgb(99, 223, 228)'}}><FcLikePlaceholder size={50}/> Destinations: {destinations}</div>
        <div className="card-dashboard" style={{color:'black',background:'rgb(207, 211, 171)'}}><MdQuiz size={50}/> Quiz Responses: {quiz}</div>
        <div className="card-dashboard" style={{color:'black',background:'lightpink'}}><FaMedium size={50}/> Medium Blogs: {blogs}</div>
        <div className="card-dashboard" style={{color:'black',background:'#e7bc6c'}}><FaBlogger size={50}/> Customer Blogs: {customBlogs}</div>
      </div>
    </div>
  );
};

export default DashboardHome;
