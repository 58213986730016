import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Contact";
import HomeSlider from "../HomeSlider";
import { Link, useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from "react-helmet-async";
import Callback from "../Callback";
import Faq from "../Faq";
import { FaHotel } from "react-icons/fa6";
import { LuPackagePlus } from "react-icons/lu";
import { MdFamilyRestroom } from "react-icons/md";
import KeralaOffer from "../KeralaOffer";
import "./index.css";

const DynamicDestinations = () => {
  const { destinationName } = useParams();
  const [destinationData, setDestinationData] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [packages, setPackages] = useState([]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchDestinationData = async () => {
      const db = firebase.firestore();
      try {
        const snapshot = await db
          .collection("Destinations")
          .doc(destinationName)
          .get();
        if (snapshot.exists) {
          setDestinationData(snapshot.data());
        } else {
          console.error("Destination data not found");
        }
      } catch (error) {
        console.error("Error fetching destination data:", error);
      }
    };

    fetchDestinationData();
  }, [destinationName]);

  useEffect(() => {
    const fetchPackages = async () => {
      if (!destinationData) return;

      const db = firebase.firestore();
      const collectionName =
        destinationData.holidayType === "Wildlife"
          ? "WildlifePackageTemplate"
          : "PackageTemplate";

      try {
        const snapshot = await db.collection(collectionName).get();
        const packageList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPackages(packageList);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchPackages();
  }, [destinationData]);

  const {
    images,
    faq,
    metaTitle,
    metaDescription,
    sections,
    contact,
    sliderOverlay,
    sliderOverlayData,
    offerPopup,
    offerPopupData,
    keywords,
    packageHead,
    holidayType,
    isKerala,
  } = destinationData || {};

  const dynamicPackages = packages.filter((pkg) => {
    return keywords.some((keyword) =>
      pkg.destinationName?.toLowerCase().includes(keyword)
    );
  });

  const sortedPackages = dynamicPackages.sort((a, b) => a.id - b.id);

  if (!destinationData) {
    return (
      <div className="loading-container">
        <p className="loading"></p>
      </div>
    );
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Navbar />
      {offerPopup && showModal && (
        <div className="modal-overlay-offer">
          <div className="modal-content-offer">
            <div className="modal-header-offer">
              <button onClick={handleCloseModal} className="close-button">
                ✖
              </button>
              <p>{offerPopupData.offerName}</p>
              <h3 className="blinking-price-offer">
                {offerPopupData.percentageOff}
              </h3>
            </div>
            <div
              className="modal-image"
              style={{
                backgroundImage:
                  "url(https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/enquire%2Foffer-1.webp?alt=media&token=a4cb3585-679a-416e-ac88-ec8b49b19ff4)",
              }}
            >
              <div className="modal-facilities">
                <div className="facility">
                  <FaHotel size={30} className="facility-icon" />
                  <p>Handpicked, High-Quality Hotels</p>
                </div>
                <div className="facility">
                  <LuPackagePlus size={30} className="facility-icon" />
                  <p>Perfectly Tailored Itineraries</p>
                </div>
                <div className="facility">
                  <MdFamilyRestroom size={30} className="facility-icon" />
                  <p>Unforgettable Family Vacations</p>
                </div>
              </div>
            </div>
            <div className="modal-buttons">
              <button onClick={handleCloseModal} className="dismiss-button">
                Dismiss
              </button>
              <a
                href="https://wa.me/917483129148?text=Hi"
                target="__blank"
                aria-label="WhatsApp"
              >
                <button className="chat-now-button">Chat Now</button>
              </a>
            </div>
          </div>
        </div>
      )}
      <Callback contactNumber={contact} />
      {sliderOverlay ? (
        <HomeSlider
          images={images}
          showOverlayText={true}
          overlayTitle={sliderOverlayData?.title || ""}
          overlayPrice={sliderOverlayData?.offerPrice || ""}
          originalPrice={sliderOverlayData?.originalPrice || ""}
          buttonText={sliderOverlayData?.buttonText || ""}
        />
      ) : (
        <HomeSlider images={images} />
      )}
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-5 dynamic-container-destination">
            <div>
              {isKerala ? <KeralaOffer /> : null}
              {sections.map((section) => (
                <div key={section.id}>
                  {(() => {
                    const listItems = [];
                    for (let i = 0; i < section.content.length; i++) {
                      const item = section.content[i];

                      if (item.tag === "li-b") {
                        const nextItem = section.content[i + 1];
                        if (nextItem && nextItem.tag === "li") {
                          listItems.push(
                            <ul key={i}>
                              <li>
                                <b>{item.content}</b> {nextItem.content}
                              </li>
                            </ul>
                          );
                          i++;
                        } else {
                          listItems.push(
                            <ul key={i}>
                              <li>
                                <b>{item.content}</b>
                              </li>
                            </ul>
                          );
                        }
                      } else if (item.tag === "li") {
                        listItems.push(
                          <ul key={i}>
                            <li>{item.content}</li>
                          </ul>
                        );
                      } else if (item.tag === "h1" || item.tag === "h2") {
                        listItems.push(
                          <h1 key={i} className={item.tag}>
                            {item.content}
                          </h1>
                        );
                      } else if (item.tag === "p") {
                        listItems.push(
                          <p key={i} className={item.tag}>
                            {item.content}
                          </p>
                        );
                      } else if (item.tag === "anchor") {
                        listItems.push(
                          <p key={i}>
                            <a
                              href={item.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item.content}
                            </a>
                          </p>
                        );
                      }
                    }
                    return <div>{listItems}</div>;
                  })()}
                </div>
              ))}
              <h2>Frequently Asked Questions(FAQs)</h2>
              <Faq faqData={faq} />
            </div>
          </div>
        </div>
      </div>
      <p className="dynamic-destination-head mb-3">{packageHead}</p>
      <div className="destinations-container mb-5">
        {holidayType === "Leisure" && (
          <div className="best-package-list">
            {sortedPackages.map((pkg) => (
              <div key={pkg.id} className="package-list-card mb-3">
                <div className="image-container-kerala">
                  <img
                    src={pkg.bannerImageUrl}
                    alt={pkg.destinationName}
                    className="list-card-img mb-3"
                  />
                  {pkg.recommend && (
                    <span className="recommend-tag">{pkg.tag}</span>
                  )}
                </div>
                <div>
                  <p className="title-list mb-3">{pkg.destinationHead}</p>
                  <p className="title-dates-list bold mb-3">{pkg.noOfDays}</p>
                  {pkg.isOffer ? (
                    <p className="mt-3 package-template-price title-rate-list-offer">
                      <span>
                        <b>
                          <span className="blinking-price">{pkg.newCost}</span>
                        </b>
                        <span className="linethrough-price">
                          {" "}
                          {pkg.originalPrice}
                        </span>
                        <span style={{ marginLeft: "5px" }}>PP</span>
                      </span>
                    </p>
                  ) : (
                    <p className="title-rate-list bold mb-3">{pkg.cost}</p>
                  )}
                  <div className="btn-container-list-card">
                    <Link
                      to={`/package/leisure/${pkg.destinationName.replace(
                        /\s+/g,
                        "-"
                      )}`}
                      className="blogs-link"
                    >
                      <button className="view-button-list-card">
                        View Itinerary
                      </button>
                    </Link>
                    <Link to="/form" className="blogs-link">
                      <button className="view-button-list-card-book">
                        Book Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {holidayType === "Wildlife" && (
          <div className="best-package-list">
            {sortedPackages.map((item) => (
              <div key={item.id} className="package-list-card mb-3">
                <div className="image-container">
                  <img
                    src={item.bannerImageUrl || item.image}
                    alt={item.destinationName || item.name}
                    className="list-card-img mb-3"
                  />
                  {item.isSold && (
                    <span className="sold-out-tag">Sold Out</span>
                  )}
                </div>
                <div>
                  <p className="title-list mb-3">
                    {item.destinationHead || item.name}
                  </p>
                  <p className="title-dates-list bold mb-3">
                    {item.noOfDays || item.days}
                  </p>
                  {item.corbett ? (
                    <p className="mt-3 title-rate-list-offer package-template-price">
                      <span>
                        <b>
                          <span className="blinking-price">{item.newCost}</span>
                        </b>
                        <span className="linethrough-price">
                          {" "}
                          {item.originalPrice}
                        </span>
                        <span style={{ marginLeft: "5px" }}>PP</span>
                      </span>
                    </p>
                  ) : (
                    <p className="title-rate-list bold mb-3">
                      {item.cost || item.price}
                    </p>
                  )}
                  <div className="btn-container-list-card">
                    <Link
                      to={`/package/wildlife/${(
                        item.destinationName || item.link
                      ).replace(/\s+/g, "-")}`}
                      className="blogs-link"
                    >
                      <button
                        className="view-button-list-card"
                        disabled={item.isSold}
                      >
                        View Itinerary
                      </button>
                    </Link>
                    <Link to="/form" className="blogs-link">
                      <button className="view-button-list-card-book">
                        Book Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </HelmetProvider>
  );
};

export default DynamicDestinations;
