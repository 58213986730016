import React from "react";
import "./index.css";
import { GiTigerHead } from "react-icons/gi";
import { GiPalmTree } from "react-icons/gi";
import { MdOutlineFlightTakeoff } from "react-icons/md";
import { BiSolidCustomize } from "react-icons/bi";

const specialities = [
  {
    title: "Expert Wildlife Safaris",
    icon: <GiTigerHead />,
  },
  {
    title: "Luxury Leisure Experiences",
    icon: <GiPalmTree />,
  },
  {
    title: "International & Domestic Reach",
    icon: <MdOutlineFlightTakeoff />,
  },
  {
    title: "Customized Tour Packages",
    icon: <BiSolidCustomize />,
  },
];

const Specialities = () => {
  return (
    <div className="specialities-container">
      {specialities.map((speciality, index) => (
        <div className="speciality-card" key={index}>
          <div className="icon">{speciality.icon}</div>
          <h3>{speciality.title}</h3>
        </div>
      ))}
    </div>
  );
};

export default Specialities;
