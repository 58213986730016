import React, { useState, useEffect, useRef, forwardRef } from "react";
import ReactSlider from "react-slider";
import "./index.css";
import DummyForm from "../DummyForm";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import ConfettiExplosion from "react-confetti-explosion";

const ImageSlider = forwardRef(
  (
    {
      images,
      showOverlayText,
      overlayTitle,
      overlayPrice,
      originalPrice,
      buttonText,
    },
    ref
  ) => {
    const [index, setIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [loadedImages, setLoadedImages] = useState([]);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isOverlayVisible, setIsOverlayVisible] = useState(true);
    const imageRefs = useRef([]);
    const [showConfetti, setShowConfetti] = useState(false);

    const openForm = () => {
      setIsFormOpen(true);
      setIsOverlayVisible(false);
      setShowConfetti(true);
    };

    const closeForm = () => {
      setIsFormOpen(false);
      setIsOverlayVisible(true);
    };

    useEffect(() => {
      images.forEach((image) => {
        const img = new Image();
        img.src = image;
        img.onload = () => setLoadedImages((prev) => [...prev, image]);
      });
    }, [images]);

    useEffect(() => {
      if (!isHovered) {
        const interval = setInterval(() => {
          setIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);
        return () => clearInterval(interval);
      }
    }, [isHovered, images.length]);

    const handleSliderChange = (value) => {
      setIndex(value);
    };

    return (
      <div
        className="slider-container"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        ref={ref}
      >
        <ReactSlider
          value={index}
          max={images.length - 1}
          onChange={handleSliderChange}
          renderThumb={(props, state) => (
            <div
              {...props}
              className="thumb"
              aria-label={`Slide ${state.value + 1}`}
            />
          )}
          className="slider"
        />
        <div className="slides">
          {images.map((image, idx) => (
            <div
              key={idx}
              className={`slide ${idx === index ? "active" : ""}`}
              ref={(el) => (imageRefs.current[idx] = el)}
            >
              {loadedImages.includes(image) && (
                <img
                  src={image}
                  alt={`Slide ${idx + 1}`}
                  className="slider-image"
                />
              )}
              <div className="confetti">
                {showConfetti && <ConfettiExplosion />}
              </div>
              {isFormOpen && (
                <div className="">
                  <div className="enquire-form-container">
                    <DummyForm onClose={closeForm} />
                  </div>
                </div>
              )}
              {showOverlayText && idx === index && isOverlayVisible && (
                <div className="overlay-text-home">
                  <h1>{overlayTitle}</h1>
                  <p className="block-rate ">
                    <span
                      className="blinking-price-home"
                      style={{ color: "orange" }}
                    >
                      {overlayPrice}
                    </span>
                    <span className="block">{originalPrice}</span> Per Person
                  </p>
                  <button className="avail-btn" onClick={openForm}>
                    {buttonText}
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
        <div
          className="arrow-home left-arrow-home"
          onClick={() => setIndex((index - 1 + images.length) % images.length)}
        >
          <BsArrowLeft size={20} />
        </div>
        <div
          className="arrow-home right-arrow-home"
          onClick={() => setIndex((index + 1) % images.length)}
        >
          <BsArrowRight size={20} />
        </div>
      </div>
    );
  }
);

export default ImageSlider;
