import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { toast, Toaster } from "react-hot-toast";
import "./index.css";

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const DestinationAddTemplate = () => {
  const [destinationName, setDestinationName] = useState("");
  const [category, setCategory] = useState("India");
  const [holidayType, setHolidayType] = useState("Leisure");
  const [images, setImages] = useState([]);
  const [contact, setContact] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [homeHeading, setHomeHeading] = useState("");
  const [homeButtonText, setHomeButtonText] = useState("");
  const [sections, setSections] = useState([{ id: 1, content: [] }]);
  const [faq, setFaq] = useState([{ question: "", answer: [""] }]);
  const [packageHead, setPackageHead] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [sliderOverlay, setSliderOverlay] = useState(false);
  const [sliderOverlayData, setSliderOverlayData] = useState({
    title: "",
    offerPrice: "",
    originalPrice: "",
    buttonText: "",
  });
  const [offerPopup, setOfferPopup] = useState(false);
  const [offerPopupData, setOfferPopupData] = useState({
    offerName: "",
    percentageOff: "",
  });

  const handleAddImage = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 5) {
      toast.error('please upload upto 5 images')
      return;
    }
    try {
      const storageRef = firebase.storage().ref();
      const uploadedImages = await Promise.all(
        files.map(async (file) => {
          const imageRef = storageRef.child(`destinationImages/${file.name}`);
          await imageRef.put(file);
          return await imageRef.getDownloadURL();
        })
      );
      setImages(uploadedImages);
    } catch (error) {
      console.error("Error uploading images: ", error);
      toast.error("Failed to upload images.");
    }
  };
  const handleAddSection = () => {
    setSections((prev) => [...prev, { id: prev.length + 1, content: [] }]);
  };
  const handleAddDynamicContent = (sectionId, tag) => {
    setSections((prev) =>
      prev.map((section) =>
        section.id === sectionId
          ? { ...section, content: [...section.content, { tag, content: "", url: "" }] }
          : section
      )
    );
  };

  const handleDynamicContentChange = (sectionId, index, field, value) => {
    setSections((prev) =>
      prev.map((section) =>
        section.id === sectionId
          ? {
              ...section,
              content: section.content.map((item, i) =>
                i === index ? { ...item, [field]: value } : item
              ),
            }
          : section
      )
    );
  };

  const handleFaqChange = (faqIndex, field, value, answerIndex) => {
    setFaq((prevFaqs) => {
      const updatedFaqs = [...prevFaqs];
      if (field === "answer") {
        updatedFaqs[faqIndex].answer[answerIndex] = value;
      } else {
        updatedFaqs[faqIndex][field] = value; 
      }
      return updatedFaqs;
    });
  };

  const handleAddLine = (faqIndex) => {
    setFaq((prevFaqs) => {
      const updatedFaqs = [...prevFaqs];
      if (!Array.isArray(updatedFaqs[faqIndex].answer)) {
        updatedFaqs[faqIndex].answer = [];
      }
      updatedFaqs[faqIndex].answer.push("");
      return updatedFaqs;
    });
  };

  const handleAddFaq = () => {
    setFaq([...faq, { question: "", answer: [""] }]); 
  };

  const handleAddKeyword = (e) => {
    if (e.key === "Enter" && e.target.value.trim()) {
      const newKeyword = e.target.value.trim();
      setKeywords((prevKeywords) => {
        if (!prevKeywords.includes(newKeyword)) {
          //console.log("Adding keyword:", newKeyword);
          return [...prevKeywords, newKeyword];
        }
        return prevKeywords;
      });
      e.target.value = ""; 
    }
  };

  const handleRemoveKeyword = (index) => {
    setKeywords((prevKeywords) =>
      prevKeywords.filter((_, i) => i !== index)
    );
  };

  const handleSubmit = async () => {
    try {
      const destinationData = {
        destinationName,
        category,
        holidayType,
        images,
        contact,
        metaTitle,
        metaDescription,
        homeHeading,
        homeButtonText,
        sections,
        faq,
        packageHead,
        keywords,
        sliderOverlay,
        sliderOverlayData: sliderOverlay ? sliderOverlayData : null,
        offerPopup,
        offerPopupData: offerPopup ? offerPopupData : null,
      };

      const db = firebase.firestore();
      await db.collection("Destinations").doc(destinationName).set(destinationData);

      toast.success("Destination added successfully!");
      setDestinationName("");
      setCategory("India");
      setHolidayType("Leisure");
      setImages([]);
      setContact("");
      setMetaTitle("");
      setMetaDescription("");
      setHomeHeading("");
      setHomeButtonText("");
      setSections([{ id: 1, content: [] }]);
      setFaq([{ question: "", answer: "" }]);
      setPackageHead("");
      setKeywords([]);
      setSliderOverlayData({
        title: "",
        offerPrice: "",
        originalPrice: "",
        buttonText: "",
      });
      setSliderOverlay(false);
      setOfferPopupData({
        offerName: "",
        percentageOff: "",
      });
      setOfferPopup(false);
      
    } catch (error) {
      console.error("Error adding destination: ", error);
     toast.error("Failed to add destination.");
    }
  };

  return (
    <>
    <Toaster toastOptions={{ duration: 5000 }} />
    <div className="destination-template container">
      <h1>Add Destination</h1>
      <div>
        <label>Destination Name</label>
        <input
          type="text"
          value={destinationName}
          onChange={(e) => setDestinationName(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Category</label>
        <select value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="India">India</option>
          <option value="International">International</option>
        </select>
      </div>
      <div>
        <label>Holiday Type</label>
        <select value={holidayType} onChange={(e) => setHolidayType(e.target.value)}>
          <option value="Leisure">Leisure</option>
          <option value="Wildlife">Wildlife</option>
        </select>
      </div>
      <div>
        <label>Images (upto 5 Images)</label>
        <input type="file" accept="image/*" multiple onChange={handleAddImage} />
        <div className="image-preview">
          {images.map((img, index) => (
            <img key={index} src={img} alt={`Uploaded ${index + 1}`} width="100" />
          ))}
        </div>
      </div>
      <div>
        <label>Contact Number</label>
        <input
          type="text"
          value={contact}
          onChange={(e) => setContact(e.target.value)}
        />
      </div>
      <div>
        <label>Meta Title</label>
        <input
          type="text"
          value={metaTitle}
          onChange={(e) => setMetaTitle(e.target.value)}
        />
      </div>
      <div>
        <label>Meta Description</label>
        <textarea
          value={metaDescription}
          onChange={(e) => setMetaDescription(e.target.value)}
        />
      </div>
      <div>
        <label>Home Page Description</label>
        <textarea
          value={homeHeading}
          onChange={(e) => setHomeHeading(e.target.value)}
        />
      </div>
      <div>
        <label>Home Page Button Text</label>
        <input
          value={homeButtonText}
          onChange={(e) => setHomeButtonText(e.target.value)}
        />
      </div>
      <div>
        <label>Slider Overlay</label>
        <select
          value={sliderOverlay}
          onChange={(e) => setSliderOverlay(e.target.value === "true")}
        >
          <option value="false">False</option>
          <option value="true">True</option>
        </select>
        {sliderOverlay && (
          <div>
            <label>Title</label>
            <input
              type="text"
              value={sliderOverlayData.title}
              onChange={(e) =>
                setSliderOverlayData({ ...sliderOverlayData, title: e.target.value })
              }
            />
            <label>Offer Price</label>
            <input
              type="text"
              value={sliderOverlayData.offerPrice}
              onChange={(e) =>
                setSliderOverlayData({ ...sliderOverlayData, offerPrice: e.target.value })
              }
            />
            <label>Original Price</label>
            <input
              type="text"
              value={sliderOverlayData.originalPrice}
              onChange={(e) =>
                setSliderOverlayData({ ...sliderOverlayData, originalPrice: e.target.value })
              }
            />
            <label>Button Text</label>
            <input
              type="text"
              value={sliderOverlayData.buttonText}
              onChange={(e) =>
                setSliderOverlayData({ ...sliderOverlayData, buttonText: e.target.value })
              }
            />
          </div>
        )}
      </div>
      <div>
        <label>Offer Popup</label>
        <select
          value={offerPopup}
          onChange={(e) => setOfferPopup(e.target.value === "true")}
        >
          <option value="false">False</option>
          <option value="true">True</option>
        </select>
        {offerPopup && (
          <div>
            <label>Offer Name</label>
            <input
              type="text"
              value={offerPopupData.offerName}
              onChange={(e) =>
                setOfferPopupData({ ...offerPopupData, offerName: e.target.value })
              }
            />
            <label>Percentage Off</label>
            <input
              type="text"
              value={offerPopupData.percentageOff}
              onChange={(e) =>
                setOfferPopupData({ ...offerPopupData, percentageOff: e.target.value })
              }
            />
          </div>
        )}
      </div>
      <div>
        <h3>Dynamic Content Sections</h3>
        {sections.map((section) => (
          <div key={section.id} className="destination-section-container">
            <h4>Section {section.id}</h4>
            <div className="destination-template-btn-section">
              {["h1","h2", "li-b", "li", "p", "anchor"].map((tag) => (
                <button
                  key={tag}
                  onClick={() => handleAddDynamicContent(section.id, tag)}
                  className="destination-template-btn-section"
                >
                  Add {tag.toUpperCase()}
                </button>
              ))}
            </div>
            {section.content.map((item, index) => (
              <div key={index}>
                <label>{item.tag.toUpperCase()}</label>
                {item.tag === "anchor" ? (
                  <>
                    <input
                      type="text"
                      placeholder="Enter URL"
                      value={item.url}
                      onChange={(e) =>
                        handleDynamicContentChange(section.id, index, "url", e.target.value)
                      }
                    />
                    <input
                      type="text"
                      placeholder="Enter Anchor Text"
                      value={item.content}
                      onChange={(e) =>
                        handleDynamicContentChange(section.id, index, "content", e.target.value)
                      }
                    />
                  </>
                ) : (
                  <textarea
                    placeholder={`Enter ${item.tag}`}
                    value={item.content}
                    onChange={(e) =>
                      handleDynamicContentChange(section.id, index, "content", e.target.value)
                    }
                  />
                )}
              </div>
            ))}
          </div>
        ))}
        <button onClick={handleAddSection} className="destination-template-btn">
          Add Section
        </button>
      </div>
      <div>
      <h3>FAQ Section</h3>
      {faq.map((item, index) => (
        <div key={index} style={{ marginBottom: "20px" }}>
          <label>Question</label>
          <input
            type="text"
            value={item.question}
            onChange={(e) => handleFaqChange(index, "question", e.target.value)}
            style={{ width: "100%", marginBottom: "10px", padding: "8px" }}
          />
          <label>Answer</label>
          {item.answer &&
        Array.isArray(item.answer) &&
        item.answer.map((line, answerIndex) => (
          <div key={answerIndex} style={{ display: "flex", marginBottom: "10px" }}>
            <input
              value={line}
              onChange={(e) =>
                handleFaqChange(index, "answer", e.target.value, answerIndex)
              }
              placeholder="Type your answer here..."
              rows="2"
              style={{
                width: "100%",
                marginRight: "10px",
                padding: "8px",
              }}
            />
          </div>
        ))}
          <button onClick={() => handleAddLine(index)} className="destination-template-btn">
            Add Line
          </button>
        </div>
      ))}
      <button onClick={handleAddFaq} className="destination-template-btn">
        Add FAQ
      </button>
    </div>
      <div>
        <label>Keywords</label>
        <input
          type="text"
          placeholder="Enter a keyword and press Enter"
          onKeyDown={handleAddKeyword}
          style={{ padding: "8px", width: "100%", marginBottom: "10px" }}
        />
        <div className="keywords-container" style={{ marginBottom: "20px" }}>
          {keywords.length > 0 ? (
            keywords.map((keyword, index) => (
              <div
                key={index}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  background: "#f1f1f1",
                  padding: "5px 10px",
                  marginRight: "5px",
                  marginBottom: "5px",
                  borderRadius: "15px",
                }}
              >
                {keyword}
                <button
                  onClick={() => handleRemoveKeyword(index)}
                  style={{
                    marginLeft: "10px",
                    background: "red",
                    color: "#fff",
                    border: "none",
                    borderRadius: "50%",
                    cursor: "pointer",
                    padding: "0 5px",
                  }}
                >
                  X
                </button>
              </div>
            ))
          ) : (
            <p style={{ fontStyle: "italic", color: "#777" }}>No keywords added yet.</p>
          )}
        </div>
      </div>
      <div>
        <label>Package Heading</label>
        <input
          type="text"
          value={packageHead}
          onChange={(e) => setPackageHead(e.target.value)}
        />
      </div>
      <button onClick={handleSubmit} className="destination-template-btn">
        Submit Destination
      </button>
    </div>
    </>
  );
};

export default DestinationAddTemplate;
