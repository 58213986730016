import React, { useState } from "react";
import "react-phone-number-input/style.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { toast, Toaster } from "react-hot-toast";
import "./index.css";
import { FiX } from "react-icons/fi";
//import { IoLogoWhatsapp } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const CustomForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    city: "",
    email: "",
    phone: "",
    destination: "",
    noOfPeople: "",
    type: "",
    arrivalDate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const db = firebase.firestore();
      await db.collection("EnquireFormTU").add({
        ...formData,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });

      const response = await fetch(
        "https://formbackend.netlify.app/enquire-now",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();
      console.log(data.message);
      toast.success("Enquiry Submitted Successfully");
      setFormData({
        name: "",
        city: "",
        email: "",
        phone: "",
        destination: "",
        noOfPeople: "",
        type: "",
        arrivalDate: "",
      });
      if (window.gtag) {
        window.gtag("event", "conversion", {
          send_to: "AW-870473623/KRgLCN7oh9QZEJe_iZ8D",
        });
      } else {
        console.error("Google Tag not initialized");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit enquiry. Please try again.");
    }
  };

  const handleEnquire = () => {
    const whatsappNumber = "917483129148";
    const message = "Hi";
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <>
      <Toaster
        toastOptions={{ duration: 5000 }}
        position="top-center"
        containerStyle={{ top: "100px" }}
      />
      <div className="form-wrapper">
        <div
          className="info-section d-none d-md-block"
          style={{ background: "rgb(202, 218, 218)" }}
        >
          <div className="info-col">
            <div>
              <h4 className="details">How it works</h4>
              <p className="details">
                1. Provide us with the details of your holiday plans.
              </p>
              <p className="details">
                2. Our travel experts will curate the itinerary based on your
                preferences and send you the best suited tour quote.
              </p>
              <p className="details">
                3. Pick the best deal and finalize your booking.
              </p>
            </div>
            <div>
              <p className="contact-number">
                <FaWhatsapp size={20} />
                <a
                  href="https://wa.me/917483129148?text=Hi"
                  target="__blank"
                  style={{ color: "black" }}
                >
                  {" "}
                  Say 'Hi' & Enquire on Whatsapp
                </a>
              </p>
              <p className="contact-number">
                <IoMdMail size={20} /> support@travelunbounded.com
              </p>
            </div>
          </div>
        </div>
        <div className="form-section">
          <button
            className="close-icon-form"
            onClick={onClose}
            aria-label="Close"
          >
            <FiX size={20} />
          </button>
          <h2 className="form-title blinking-price" style={{ color: "green" }}>
            Enquire Now
          </h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              className="form-input"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="form-input"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <input
              type="text"
              name="phone"
              placeholder="Phone Number"
              className="form-input"
              value={formData.phone}
              onChange={handleChange}
              required
            />

            <input
              type="text"
              name="city"
              placeholder="City"
              className="form-input"
              value={formData.city}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="destination"
              placeholder="Travel Destination"
              className="form-input"
              value={formData.destination}
              onChange={handleChange}
              required
            />
            <input
              type="number"
              name="noOfPeople"
              placeholder="No. of Travellers"
              className="form-input"
              value={formData.noOfPeople}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="arrivalDate"
              className="form-input"
              placeholder="Arrival Date"
              onFocus={(e) => {
                e.target.type = "date";
                e.target.showPicker();
              }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  e.target.type = "text";
                }
              }}
              value={formData.arrivalDate}
              onChange={handleChange}
              required
            />
            <select
              name="type"
              className="form-input"
              value={formData.type}
              onChange={handleChange}
              required
            >
              <option value="">Vacation Type</option>
              <option value="Family">Family</option>
              <option value="Honeymoon">Honeymoon</option>
              <option value="Wildlife">Wildlife</option>
              <option value="Leisure">Leisure</option>
            </select>
            <button type="submit" className="submit-button">
              Submit
            </button>
            <button
              type="submit"
              className="submit-button-whatsapp"
              onClick={handleEnquire}
            >
              <FaWhatsapp size={20} /> Need Live assistance Chat Now
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CustomForm;
