import React from "react";
import "./index.css";

const HorizontalRow = () => {
  return (
    <div className="horizontal-row-container">
      <div className="decorative-line">
        <div className="line full-line"></div>
        <div className="ornament">✦Travel Unbounded✦</div>
        <div className="line full-line"></div>
      </div>
    </div>
  );
};

export default HorizontalRow;
